import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container } from 'react-bootstrap';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container className="singleCol text-center py-5">
      <h1><strong>404: Not Found</strong></h1>
      <p>You just hit a route that doesn&#39;t exist. <Link to="/">Click here</Link> to head back to our home page.</p>
    </Container>
    
  </Layout>
)

export default NotFoundPage
